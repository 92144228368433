<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Register v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <img
                        src="@/assets/images/logo/logo.png"
                        alt="Logo"
                        class="logo-auth"
                    />
                    <h2 class="brand-text text-primary ml-1">Leechall.io</h2>
                </b-link>

                <!-- alert -->
                <b-alert :variant="variant" show v-if="status">
                    <div class="alert-body">{{ status }}</div>
                </b-alert>

                <b-card-text class="mb-1">
                    We'll send you a link via email to to verify your account.
                </b-card-text>

                <!-- form -->
                <validation-observer ref="registerForm" #default="{ invalid }">
                    <b-form
                        class="auth-register-form mt-2"
                        @submit.prevent="onSubmit"
                    >
                        <!-- Name -->
                        <b-form-group label="Name" label-for="name">
                            <validation-provider
                                #default="{ errors }"
                                name="name"
                                rules="required|min:3"
                            >
                                <b-form-input
                                    id="name"
                                    v-model="name"
                                    :state="errors.length > 0 ? false : null"
                                    name="register-name"
                                    placeholder="Johndoe"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- email -->
                        <b-form-group label="Email" label-for="email">
                            <validation-provider
                                #default="{ errors }"
                                name="email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="email"
                                    :state="errors.length > 0 ? false : null"
                                    name="register-email"
                                    placeholder="john@example.com"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group label="Password" label-for="password">
                            <validation-provider
                                #default="{ errors }"
                                name="password"
                                rules="required|min:6"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="password"
                                        :type="password1FieldType"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        class="form-control-merge"
                                        name="password"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="password1ToggleIcon"
                                            @click="togglePassword1Visibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- confirm password -->
                        <b-form-group
                            label-for="password-confirm"
                            label="Confirm Password"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Confirm Password"
                                rules="required|confirmed:password"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="password-confirm"
                                        v-model="password_confirmation"
                                        :type="password2FieldType"
                                        class="form-control-merge"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="password-confirm"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="password2ToggleIcon"
                                            @click="togglePassword2Visibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- checkbox -->
                        <b-form-group>
                            <b-form-checkbox
                                id="register-privacy-policy"
                                name="checkbox-1"
                                required
                            >
                                I agree to
                                <b-link
                                    :to="{ name: 'privacy' }"
                                    target="_blank"
                                    >Privacy Policy
                                </b-link>
                                &
                                <b-link :to="{ name: 'terms' }" target="_blank">
                                    Terms of Use
                                </b-link>
                            </b-form-checkbox>
                        </b-form-group>

                        <!-- <vue-recaptcha
                            class="mt-1"
                            ref="reCaptcha"
                            :sitekey="sitekey"
                            @verify="onRegister"
                            @expired="onExpired"
                            size="invisible"
                        /> -->

                        <vue-hcaptcha
                            class="mt-1"
                            :sitekey="sitekey"
                            size="invisible"
                            ref="hCaptcha"
                            @verify="onRegister"
                            @expired="onExpired"
                        />

                        <!-- overlay -->
                        <b-overlay
                            :show="loading"
                            rounded
                            opacity="0.6"
                            spinner-variant="primary"
                        >
                            <!-- submit button -->
                            <b-button
                                variant="primary"
                                block
                                type="submit"
                                :disabled="invalid"
                            >
                                Sign up
                            </b-button>
                        </b-overlay>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <span>Already have an account? </span>
                    <b-link :to="{ name: 'auth-login' }">
                        <span>Sign in instead</span>
                    </b-link>
                </b-card-text>
            </b-card>
            <!-- /Register v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BOverlay,
    BAlert,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
} from "bootstrap-vue";
import { required, email, min } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
    components: {
        // BSV
        BOverlay,
        BAlert,
        BCard,
        BLink,
        BCardTitle,
        BCardText,
        BForm,
        BButton,
        BFormInput,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        // validations
        ValidationProvider,
        ValidationObserver,
        VueRecaptcha,
        VueHcaptcha,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            sitekey: process.env.VUE_APP_HCAPTCHA,
            email: "",
            name: "",
            password: "",
            password_confirmation: "",
            variant: "",
            status: "",
            loading: false,

            // validation rules
            required,
            email,
            min,

            // Toggle Password
            password1FieldType: "password",
            password2FieldType: "password",
        };
    },
    computed: {
        password1ToggleIcon() {
            return this.password1FieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
        password2ToggleIcon() {
            return this.password2FieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
    },
    methods: {
        togglePassword1Visibility() {
            this.password1FieldType =
                this.password1FieldType === "password" ? "text" : "password";
        },
        togglePassword2Visibility() {
            this.password2FieldType =
                this.password2FieldType === "password" ? "text" : "password";
        },
        onExpired() {
            this.$refs.hCaptcha.reset();
        },
        onSubmit() {
            this.$refs.hCaptcha.execute();
        },

        onRegister(response) {
            this.status = "";
            this.loading = true;
            axios
                .post("auth/register", {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    "h-captcha-response": response,
                })
                .then((response) => {
                    if (response.data.success) {
                        this.variant = "success";
                        this.status = response.data.message;
                        this.$store.dispatch(
                            "setToken",
                            response.data.access_token
                        );
                        this.$router.push({ name: "auth-verify" });
                    } else {
                        this.loading = false;
                        this.variant = "danger";
                        this.status = response.data.message;
                    }
                    this.onExpired();
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.data.errors != undefined) {
                        if (
                            "h-captcha-response" in error.response.data.errors
                        ) {
                            this.variant = "danger";
                            this.status =
                                error.response.data.errors[
                                    "h-captcha-response"
                                ][0];
                        } else {
                            this.$refs.registerForm.setErrors(
                                error.response.data.errors
                            );
                        }
                    }
                    this.onExpired();
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
